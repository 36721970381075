<template>
    <v-card v-if="loading">
        <v-card-text class="pa-15 text-center">
            <v-progress-circular
                color="primary"
                indeterminate
            />
        </v-card-text>
    </v-card>
    <v-card v-else-if="config.finished && showAdded">
        <AddToPurchaseDialog
            @close="cancel"
        />
    </v-card>
    <v-card v-else>
        <v-card-title class="headline mb-2" v-text="cmpItem.name" />
        <v-card-text class="mb-0 pb-0">
            <v-row>
                <v-col cols="9">
                    <v-stepper v-model="curStep">
                        <v-stepper-header>
                            <v-stepper-step
                                v-for="(step, i) in steps"
                                edit-icon="$complete"
                                :key="i"
                                :step="i + 1"
                                :editable="curStep > i + 1 || edit"
                                :complete="curStep > i + 1 || edit"
                                @click="curStep = i + 1"
                                :ref="`step-${i+1}`"
                            >{{ step.name }}</v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content
                                v-for="(step, i) in steps"
                                :step="i + 1"
                                :key="`${i}-content`"
                            >
                                <component
                                    :is="step.component"
                                    :ref="step.ref"
                                    @nextStep="nextStep"
                                />
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-col>
                <v-col cols="3">
                    <div class="sticky-top pt-3">
                        <ItemImage
                            :height="100"
                            :item="cmpItem"
                        />
                        <div class="overline mb-n2 text-center">Sub-total</div>
                        <div class="text-h4 text-center">
                            <template v-if="!cmpItem.meta.tbd_price">
                                {{ fmtNumber(configuredItemPrice, {currency: true}) }}
                            </template>
                            <template v-else>
                                $ TBD
                            </template>
                        </div>
                        <div
                            v-if="category && category.meta && category.meta.price_subsidy && configuredItemPrice > category.meta.price_subsidy.staff"
                            class="text-center"
                        >
                            <v-divider class="ma-2" />
                            <v-row no-gutters>
                                <!--
                                <v-col cols="8" class="text-right pr-2">Covered by Dept:</v-col>
                                <v-col cols="4" class="text-left font-weight-bold">${{ category.meta.price_subsidy.staff }}</v-col>
                                -->
                                
                                <v-col cols="8" class="text-right pr-2">MD Responsible:</v-col>
                                <v-col cols="4" class="text-left font-weight-bold">${{ fmtNumber(Math.max(configuredItemPrice - category.meta.price_subsidy.md, 0)) }}</v-col>

                                <v-col cols="8" class="text-right pr-2">Staff Responsible:</v-col>
                                <v-col cols="4" class="text-left font-weight-bold">${{ fmtNumber(Math.max(configuredItemPrice - category.meta.price_subsidy.staff, 0)) }}</v-col>
                            </v-row>
                        </div>
                        <div class="text-center caption">
                            *All prices are subject to change
                        </div>
                    </div>
                    
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
            <v-spacer />
            <v-btn
                color="blue darken-1"
                v-text="cancelText"
                @click="cancel"
                text
            />
            <v-btn
                v-show="curStep && curStep !== steps.length"
                color="blue darken-1"
                v-text="nextText"
                @click="nextStep"
                text
            />
            <v-btn
                v-show="curStep && curStep === steps.length"
                color="blue darken-1"
                v-text="finishText"
                @click="finish"
                text
            />
        </v-card-actions>
    </v-card>
</template>
<script>
import { reactive, computed, ref, watch } from '@vue/composition-api';

import numberHelper from '@/composables/common/number';
import useItem from '@/composables/purchasing/useItem';

import ItemImage from '@/components/purchasing/ItemImage';
import HardwareStep from '@/components/purchasing/steps/HardwareStep';
import SoftwareStep from '@/components/purchasing/steps/SoftwareStep';
import ReviewStep from '@/components/purchasing/steps/ReviewStep';
import InstallStep from '@/components/purchasing/steps/InstallStep';
import AccessoryStep from '@/components/purchasing/steps/AccessoryStep';
import NotesStep from '@/components/purchasing/steps/NotesStep';
import AccountStep from '@/components/purchasing/steps/AccountStep';
import AddToPurchaseDialog from '@/components/purchasing/AddToPurchaseDialog';

export default {
    name: 'ItemStepper',
    components: {
        ItemImage,
        HardwareStep,
        SoftwareStep,
        AccessoryStep,
        ReviewStep,
        InstallStep,
        NotesStep,
        AccountStep,
        AddToPurchaseDialog
    },
    props: {
        item: {
            type: Object, 
            default() {
                return {}
            }
        },
        edit: {
            type: Boolean,
            default: false
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        nextText: {
            type: String,
            default: 'Next'
        },
        finishText: {
            type: String,
            default: 'Add To Purchase'
        },
        showAdded: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        ticketStatus: {
            type: String
        }
    },
    emits: ['cancel', 'finish'],
    setup(props, context) {

        const store = context.root.$store;

        const { fmtNumber } = numberHelper();

        const curStep = ref(1);

        const config = reactive({
            loadingSubmit: false,
            finished: false
        });

        const {
            category,
            itemFull,
            configuredItemPrice
        } = useItem(context);


        const allSteps = {
            'Hardware': {
                name: 'Hardware',
                component: HardwareStep,
                ref: 'hwStep'
            },
            'Software': {
                name: 'Software',
                component: SoftwareStep,
                ref: 'swStep'
            },
            'Accessories': {
                name: 'Accessories',
                component: AccessoryStep,
                ref: 'accStep'
            },
            'Notes': {
                'name': 'Notes',
                component: NotesStep,
                ref: 'notesStep'
            },
            'Install': {
                name: 'Install',
                component: InstallStep,
                ref: 'installStep'
            },
            'Accounts': {
                name: 'Accounts',
                'component': AccountStep,
                ref: 'accountStep'
            },
            'Review': {
                name: 'Review',
                component: ReviewStep
            }
        };

        const steps = computed(() => {

            const iv = itemFull.value;
            let useSteps = [];
            const skipSteps = [];

            if (!iv.related || !iv.related['Accessories'] || !iv.related['Accessories'].length) skipSteps.push('Accessories');

            if (iv.options && !iv.options.length && !iv.features) skipSteps.push('Hardware');

            if(!iv.related || !iv.related['Accounts'] || !iv.related['Accounts'].length) skipSteps.push('Accounts');
            // itemFull.value.options && itemFull.value.options.length
            
            if (props.edit && (props.ticketStatus && props.ticketStatus !== 'Division Review')){
                skipSteps.push('Accessories');
                skipSteps.push('Software');
                skipSteps.push('Accounts');
            
            }

            if (category.value?.meta?.steps && category.value.meta.steps.length) {
                for (const s of category.value.meta.steps) {
                    if (skipSteps.includes(s.name)) continue;
                    useSteps.push(allSteps[s.name]);
                }
            }

            useSteps.push(allSteps['Review']);

            return useSteps;
        });


        const cancel = () => {
            context.emit('cancel');
        };

        const nextStep = () => {
            if (context.refs[steps.value[curStep.value - 1].ref] && !context.refs[steps.value[curStep.value - 1].ref][0].validate()) return;
            curStep.value = Math.min(curStep.value + 1, steps.value.length);
        };

        const finish = async () => {
            config.loadingSubmit = true;

            context.emit('finish');

            config.loadingSubmit = false;
            curStep.value = 1;
            store.dispatch('purchasing/resetItem');
            config.finished = true;
        };

        const cmpItem = computed(() => !props.edit ? props.item : props.item.item)

        // scroll to hidden steps if too many steps
        watch(curStep, () => {
            const comp = context.refs[`step-${curStep.value}`][0];
            comp.$el.scrollIntoView();
        });

        return {
            config,
            itemFull,
            configuredItemPrice,
            cancel,
            curStep,
            steps,
            nextStep,
            fmtNumber,
            finish,
            category,
            cmpItem
        }
    },
};
</script>
<style>
.sticky-top {
    position: sticky;
    top: 0;
}

.v-stepper__header {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
}
</style>