<template>
    <div
        v-if="hasDetails.any"
        style="display: contents"
    >
        <div
            v-if="!dense"
            class="body-2 detail text-uppercase"
            @click="showDetail = !showDetail"
        >
            Details<v-icon v-text="showDetail ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
        </div>
        <div
            v-else
            class="mt-1"
            @click="showDetail = !showDetail"
        >
            <template v-if="hasDetails.assignment">
                <v-chip
                    v-if="item.assignment.assignTo"
                    class="mr-2"
                    label
                    small
                    outlined
                >
                    <v-icon small left>
                        mdi-account
                    </v-icon>
                    <span v-if="item.assignment.assignTo.user">
                        {{ item.assignment.assignTo.user.first_name }} {{ item.assignment.assignTo.user.last_name }}
                    </span>
                    <span v-else>
                        {{ item.assignment.assignTo.first_name }} {{ item.assignment.assignTo.last_name }}
                    </span>
                    
                </v-chip>
                <v-chip
                    v-else-if="item.assignment.usedBy"
                    class="mr-2"
                    label
                    small
                    outlined
                >
                    <v-icon small left>
                        mdi-account-group
                    </v-icon>
                    Multiple
                </v-chip>
            </template>
            <v-chip
                v-if="hasDetails.software"
                class="mr-2"
                label
                small
                outlined
            >
                <v-icon small left>
                    mdi-application
                </v-icon>
                Software
            </v-chip>
            <v-chip
                v-if="hasDetails.accounts"
                class="mr-2"
                label
                small
                outlined
            >
                <v-icon small left>
                    mdi-account
                </v-icon>
                Accounts
            </v-chip>
            <v-chip
                v-if="hasDetails.accessories"
                class="mr-2"
                label
                small
                outlined
            >
                <v-icon small left>
                    mdi-devices
                </v-icon>
                Accessories
            </v-chip>
            <v-chip
                v-if="hasDetails.color"
                class="mr-2"
                label
                small
                outlined
            >
                <v-icon
                    :color="item.option.meta.colors[item.config.Color].color"
                    small
                    left
                >
                    mdi-checkbox-blank-circle
                </v-icon>
                {{ item.config.Color }}
            </v-chip>
            <template v-if="hasDetails.config">
                <v-chip
                    v-if="item.config.Storage"
                    class="mr-2"
                    label
                    small
                    outlined
                >
                    <v-icon small left>
                        mdi-database-outline
                    </v-icon>
                    {{ item.config.Storage.name }}
                </v-chip>
                <v-chip
                    v-if="item.config.Memory"
                    class="mr-2"
                    label
                    small
                    outlined
                >
                    <v-icon small left>
                        mdi-memory
                    </v-icon>
                    {{ item.config.Memory.name }}
                </v-chip>
            </template>
            <template v-if="hasDetails.other">
                <v-chip
                    v-if="item.other.applecare"
                    class="mr-2"
                    label
                    small
                    outlined
                >
                    <v-icon small left>
                        mdi-apple
                    </v-icon>
                    AppleCare+
                </v-chip>
            </template>

            <v-chip
                v-if="hasDetails.replacement"
                class="mr-2"
                label
                small
                outlined
            >
                <v-icon
                    small
                    left
                >
                    mdi-sync
                </v-icon>
                Replacement
            </v-chip>
            <v-icon
                style="cursor: pointer"
                v-text="showDetail ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                icon
            />
        </div>

        <v-expand-transition>    
            <div v-show="showDetail">
                <v-sheet
                    :class="['pl-4 pt-2 pb-2', {'mt-2': dense}, {'pr-4': expandOutline}]"
                    rounded="lg"
                    :outlined="expandOutline"
                >


                    <!--    ITEM    -->
                    <template v-if="showItem">
                        <div
                            :class="config.headerClass"
                        >Item</div>
                        <v-row no-gutters>
                            <v-col>
                                {{ item.item.name }}
                                <div class="caption text--secondary" v-if="item.option && (item.option.name !== item.item.name)">
                                    {{ item.option.name }}
                                </div>
                            </v-col>
                            <v-col class="text-end" cols="auto">
                                ${{ getItemPrice(item) }}
                                
                            </v-col>
                        </v-row>
                        <v-divider class="my-1" />
                    </template>



                    <!--    CONFIG    -->
                    <template v-if="item.config">
                        <template v-for="(v, k, i) in item.config">
                            <div :key="`cf${i}`" class="pb-2">

                                <!--    CONFIG - COLOR    -->
                                <template v-if="k === 'Color'">
                                    <div
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-avatar
                                                class="mr-2"
                                                size="20"
                                                :color="item.option.meta.colors[v].color"
                                            />
                                            {{ v }}
                                        </v-col>
                                    </v-row>
                                </template>
                                

                                <!--    CONFIG - SOFTWARE    -->
                                <template v-else-if="k === 'Software'">
                                    <div
                                        v-if="v.length"
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row
                                        v-for="(s, i2) in v"
                                        :key="`sw${i2}`"
                                        align="center"
                                    >
                                        <v-col cols="1" v-if="s.image">
                                            <v-img
                                                height="30"
                                                :src="s.image"
                                                contain
                                            />
                                        </v-col>
                                        <v-col>
                                            {{ s.name }}
                                        </v-col>
                                        <v-col class="text-end d-flex align--center justify-end body-1">
                                            +${{ fmtNumber(s.est_price) }}
                                        </v-col>
                                    </v-row>
                                </template>


                                <!--    CONFIG - Software Notes    -->
                                <template v-else-if="k === 'Software Notes'">
                                    <div
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row>
                                        <v-col>
                                            {{ v }}
                                        </v-col>
                                    </v-row>
                                </template>


                                <!--    CONFIG - Accounts   -->
                                <template v-else-if="k === 'Accounts'">
                                    <div
                                        v-if="v.length"
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row
                                        v-for="(a, i3) in v"
                                        :key="`account${i3}`"
                                        align="center"
                                    >
                                        <v-col cols="1" v-if="a.image">
                                            <v-img
                                                height="30"
                                                :src="a.image"
                                                contain
                                            />
                                        </v-col>
                                        <v-col
                                            v-else   
                                            class="display: flex; justify-content: center;"
                                            cols="1"
                                        >
                                            <v-icon
                                                color="black"
                                                :size="30"
                                            >mdi-account-plus</v-icon>
                                        </v-col>
                                        <v-col>
                                            {{ a.name }}
                                        </v-col>
                                        <v-col class="text-end d-flex align--center justify-end body-1">
                                            +${{ fmtNumber(a.est_price) }}
                                        </v-col>
                                    </v-row>
                                </template>


                                <!--    CONFIG - Account Notes    -->
                                <template v-else-if="k === 'Account Notes'">
                                    <div
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row>
                                        <v-col>
                                            {{ v }}
                                        </v-col>
                                    </v-row>
                                </template>


                                <!--    OTHER CONFIG    -->
                                <template v-else>
                                    <div
                                        :class="config.headerClass"
                                        v-text="k"
                                    />
                                    <v-row no-gutters>
                                        <v-col>
                                            {{ v.name }}
                                        </v-col>
                                        <v-col v-if="v.price" class="text-end body-1">
                                            +${{ fmtNumber(v.price) }}
                                        </v-col>
                                    </v-row>
                                </template>
                            </div>
                        </template>
                    </template>


                    <!--    ACCESSORIES    -->
                    <template v-if="hasDetails.accessories">
                        <div :class="config.headerClass">Accessories</div>
                        <div class="pb-2">
                            <template v-for="(v, k, i) in item.accessories">
                                <v-row
                                    v-if="v.qty"
                                    class="d-flex align-center"
                                    :key="`acc${i}`"
                                >
                                    <v-col cols="1" v-if="v.item.image">
                                        <v-img
                                            height="40"
                                            :src="v.item.image"
                                            contain
                                        />
                                    </v-col>
                                    <v-col>
                                        <div>{{ v.qty }} x <span v-if="v.color">{{ v.color[0] }}</span> {{ v.item.name }}</div>
                                        <div v-if="v.item.meta && v.item.meta.applecare && v.item.meta.applecare.required">
                                            <v-chip class="" small label outlined>3 Year AppleCare+ Included</v-chip>
                                        </div>
                                        <!-- <div class="caption text--secondary mt-1">{{ fmtNumber(getAccessoryPrice(v), {currency: true}) }}</div> -->
                                        <div v-if="v.config">
                                            <ul>
                                                <li class="caption text--secondary mt-1" v-for="(opt,acKey) in v.config" :key="acKey">
                                                    {{ acKey }}: {{ opt.name }} <span v-if="opt.price">(+${{ fmtNumber(opt.price) }})</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </v-col>
                                    <v-col v-if="v.item.est_price" class="text-end">
                                        +${{ fmtNumber((v.qty || 1) * getAccessoryPrice(v)) }}
                                    </v-col>
                                </v-row>
                            </template>
                        </div>
                    </template>



                    <!--    REPLACEMENT    -->
                    <template v-if="hasDetails.replacement">
                        <div class="body-2 pb-2">
                        <div :class="config.headerClass">Replacing</div>
                            <template v-if="item.replacement.endpoints && item.replacement.endpoints.length">
                                <v-row dense>
                                    <v-col
                                        v-for="(replItem, i) in item.replacement.endpoints"
                                        cols="12"
                                        :key="i"
                                    >
                                        <v-icon
                                            v-text="endpointIcon[replItem.endpoint_type]"
                                            left
                                        />
                                        {{ replItem.full_name }}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-icon
                                    v-text="endpointIcon[item.replacement.endpoint.endpoint_type]"
                                    left
                                />
                                {{ item.replacement.endpoint.full_name }}
                            </template>
                        </div>
                    </template>



                    <!--    ACCOUNTS   -->
                    <template v-if="hasDetails.accounts && hasDetails.accounts.length">
                        <div :class="config.headerClass">Accounts</div>
                        {{ hasDetails.accounts }}
                        <div class="pb-2">
                            <template v-for="(v, k, i) in item.accounts">
                                <v-row
                                    v-if="v.qty"
                                    class="d-flex align-center"
                                    :key="`account${i}`"
                                >
                                    <v-col cols="1" v-if="v.item.image">
                                        <v-img
                                            height="40"
                                            :src="v.item.image"
                                            contain
                                        />
                                    </v-col>
                                    <v-col>
                                        <div>{{ v.qty }} x {{ v.item.name }}</div>
                                        <div class="caption text--secondary mt-n1">{{ fmtNumber(v.item.est_price, {currency: true}) }}</div>
                                    </v-col>
                                    <v-col v-if="v.item.est_price" class="text-end">
                                        +${{ fmtNumber((v.qty || 1) * v.item.est_price) }}
                                    </v-col>
                                </v-row>
                            </template>
                        </div>
                    </template>



                    <!--    OTHER    -->
                    <template v-if="item.item.meta && item.item.meta.applecare && item.item.meta.applecare.price">
                        <div :class="config.headerClass">Other</div>
                        <v-row no-gutters>
                            <v-col>
                                AppleCare+
                                <div class="caption text--secondary">3 years of accidental damage protection and expert technical support from Apple</div>
                            </v-col>
                            <v-col
                                class="text-end body-1"
                                cols="auto"
                            >
                                +{{ fmtNumber(item.other && item.other.applecare && item.other.applecare.price || item.item.meta.applecare.price, {currency: true}) }}
                            </v-col>
                        </v-row>
                    </template>



                    <!--    NOTES    -->

                    <template v-if="hasDetails.notes">
                        <div :class="config.headerClass">Notes</div>
                        <div
                            class="body-2 pb-2"
                            style="white-space: break-spaces"
                            v-text="item.notes"
                        />
                    </template>
                </v-sheet>
            </div>
        </v-expand-transition>
    </div>
</template>
<script>
import { ref, computed, onBeforeMount } from '@vue/composition-api';


import numberHelper from '@/composables/common/number';
import objectHelper from '@/composables/common/object';
import useItem from '@/composables/purchasing/useItem';

export default {
    name: 'ItemDetails',
    props: {
        item: {
            type: Object
        },
        expand: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: false
        },
        expandOutline: {
            type: Boolean,
            default: true
        },
        showItem: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {

        const { fmtNumber } = numberHelper();

        const { omitKey } = objectHelper();

        const showDetail = ref(true);

        const config = {
            headerClass: 'text-h6 font-weight-light grey--text text--darken-2'
        };

        const { getAccessoryPrice } = useItem(context);

        const hasDetails = computed(() => {
            let det = {};
            if (props.item.config) {
                det = {
                    software: !!(props.item.config.Software && props.item.config.Software.length),
                    accounts: !!(props.item.config.Accounts && props.item.config.Accounts.length),
                    config: !!(Object.values(omitKey(['Software', 'Color', 'Accounts'], props.item.config)).length),
                    color: !!(props.item.config.Color),
                }
            }

            if (props.item.assignment) {
                det.assignment = true;
            }
            
            if (props.item.accessories && Object.keys(props.item.accessories).length) {
                det.accessories = true;
            }

            if (props.item.notes) {
                det.notes = true;
            }

            if (props.item.replacement) {
                det.replacement = !!(
                    props.item.replacement.endpoint || (props.item.replacement.endpoints && props.item.replacement.endpoints.length)
                );
            }


            if (props.item.other && Object.entries(props.item.other).filter(([k]) => k !== 'id').length) {
                det.other = true;
            }

            det.any = Object.values(det).some(x => x);

            return det;
        });

        const getItemPrice = (item) => {
            if (item.item.meta && item.item.meta.color_price) {
                let color = item.config['Color']
                if (item.option && item.option.meta && item.option.meta.colors && item.option.meta.colors[color]) {
                    return fmtNumber(item.option.meta.colors[color].price);
                }
            }
            
            return fmtNumber(item.option && item.option.est_price ? item.option.est_price : item.item.est_price)
        }   


        onBeforeMount(() => {
            showDetail.value = !!props.expand;
        });

        return {
            fmtNumber,
            hasDetails,
            showDetail,
            config,
            getAccessoryPrice,
            getItemPrice
        }
    }
};
</script>
<style scoped>
.detail {
    cursor: pointer;
    color: #1565c0;
    text-decoration: underline;
}
</style>
