import axios from 'axios';

import { ref, computed } from '@vue/composition-api';


export default function requestHelper() {

    const loadedRequest = ref({});

    const requestItems = ref([]);

    const requestCharges = ref({});

    const requestTickets = ref([]);

    const loadRequest = async (id, opt={}) => {

        let req, response;

        if (opt.loadCharges) {
            response = await axios.get(`/purchasing/load_charges/${id}/?full=1`);

            req = response.data.request;

            requestCharges.value = response.data.charges.map(x => {
                return {
                    ...x.chart_string,
                    amount: x.amount,
                    percent: x.percent,
                    processed: x.processed
                };
            });
        
        } else {
            response = await axios.get(`/purchasing/load_request/${id}/`);
            req = response.data;
            if (!response.data.pk) return false;
        }


        // final price checks / integration
        let purTickets = [];
        for (const ticket of req.tickets) {

            purTickets.push({
                id: ticket.pk,
                key: ticket.jira_key
            })

            for (const item of ticket.items) {
                const fpb = item.final_price_breakdown || {};

                item.price = item.final_price || item.est_price;
                
                if (fpb.item) {

                    item.item.est_price = fpb.item;

                    // option
                    if (fpb.option_pk) item.option.est_price = fpb.item;

                    // config / software / color
                    for (const k of Object.keys(item.config || {})) {

                        if (fpb.config[k]) {
                            item.config[k] = fpb.config[k];

                        } else if (k === 'Software' && fpb.software) {

                            item.config['Software'].map(x => {
                                const fpbsw = fpb.software.find(y => y.pk === x.pk) || {};
                                x.est_price = fpbsw.price ?? x.est_price;
                            });

                        }  
                    }

                    // accessories
                    for (const k of Object.keys(item.accessories || {})) {
                        const fpbac = fpb.accessories[k];

                        if (fpbac && item.accessories[k].item.est_price) {
                            item.accessories[k].item.est_price = fpbac.item.price;
                        }
                    }

                    // other
                    for (const k of Object.keys(item.other || item.item?.meta?.applecare?.price || {})) {
                        if (k === 'applecare') {
                            item.other.applecare.price = fpb.other?.applecare?.price || item.other.applecare.price;
                        }
                    }
                }
            }
        }
        
        loadedRequest.value = req;
        requestTickets.value = purTickets;

        return req;
    };
    

    const itemsBreakdown = computed(() => {

        let bdItems = {
            itemTotals: []
        };

        let total = 0;

        for (const item of requestItems.value) {

            let itemTotal = 0

            let itemVal = {};

            // option
            if (item.option && item.option.pk && !(item.item.name === item.option.name && item.item.est_price === item.option.est_price)) {
                itemVal.option = item.option;

                itemTotal += Number(item.option.est_price)
            } else if (item.item.est_price) {
                itemTotal += Number(item.item.est_price);
            }

            // config
            for (const [ck, cv] of Object.entries(item.config || {})) {

                if (ck === 'Color') {
                    itemVal.color = {
                        name: cv
                    };

                    if (item.option?.meta?.colors && item.option.meta.colors[cv]) {
                        itemVal.color.color = item.option.meta.colors[cv].color;
                    }
                    continue;

                } else if (ck === 'Software') {
                    itemVal.software = cv.map(x => {
                        return {...x, source: 'Vendor'}
                    });
                    itemTotal += cv.reduce((sum, item) => (sum += Number(item.est_price || 0)), 0);
                    continue;

                } else {
                    if (!itemVal.config) itemVal['config'] = {};
                    itemTotal += Number(cv.price || 0);
                    itemVal.config[ck] = {
                        name: cv.name,
                        price: cv.price
                        // source: 'Vendor'
                    }
                }
            }

            // other
            if (item.other) {
                itemVal.other = item.other;
                if (item.other.applecare && item.other.applecare.price) {
                    itemTotal += Number(item.other.applecare.price);
                }
            }

            // accessories
            if (item.accessories && Object.keys(item.accessories).length) {

                for (let acv of Object.values(item.accessories)) {
                    acv.source = 'Vendor'
                }

                itemVal.accessories = item.accessories;
      

                itemTotal += Object.values(item.accessories).reduce(
                    (sum, item) => (sum += (Number(item.qty || 1) * Number(item.item?.est_price || 0))), 0
                );
            }

            itemVal.source = 'Vendor';

            bdItems[item.item.pk] = itemVal;

            bdItems.itemTotals.push(itemTotal);

            total += itemTotal;
        }

        bdItems.total = total;

        return bdItems;
    });



    const chgPrice = (idx, prop, price, prop2=null) => {
            
        price = Number(price);

        switch (prop) {
            case 'item': {
                requestItems.value[idx].item.est_price = price;
                break;
            }

            case 'option': {
                requestItems.value[idx].option.est_price = price;
                break;
            }

            case 'config': {
                requestItems.value[idx].config[prop2].price = price;
                break;
            }

            case 'software': {
                requestItems.value[idx].config['Software'][prop2].est_price = price;
                break;
            }

            case 'accessory': {
                const aItem = requestItems.value[idx].accessories[prop2];
                const newPrice = Math.round(((price / aItem.qty || 1)) * 100) / 100;
                requestItems.value[idx].accessories[prop2].item.est_price = newPrice;
                break;
            }

            case 'other': {
                requestItems.value[idx].other[prop2].price = price;
                break;
            }
        }
    };


    const chgSource = (idx, prop, src) => {
        console.log(JSON.stringify(src));
    };

    return {
        loadRequest,
        loadedRequest,
        requestItems,
        itemsBreakdown,
        chgPrice,
        chgSource,
        requestCharges,
        requestTickets
    };
}
